import * as React from 'react';
import {
  Navbar,
  NavbarBrand,
  NavbarItem,
  Icon,
  NavbarBurger,
  Container,
  NavbarMenu,
  NavbarEnd,
  Field,
  Control,
  Button,
  NavbarStart,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownContent,
  DropdownItem,
  DropdownDivider,
} from 'bloomer';

import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import { User } from '@firebase/auth-types';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  useCollectionData,
  useCollection,
} from 'react-firebase-hooks/firestore';

export const Nav = (): React.ReactElement => {
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const { initialising, user } = useAuthState(firebase.auth());

  function authButton(): React.ReactElement {
    if (initialising) {
      return null;
    }

    if (user) {
      return (
        <Field isGrouped>
          <Control>
            <Button onClick={() => firebase.auth().signOut()} isColor='primary'>
              <span>Sign out</span>
            </Button>
          </Control>
        </Field>
      );
    }

    return (
      <Field isGrouped>
        <Control>
          <Button
            onClick={() =>
              firebase
                .auth()
                .signInWithPopup(new firebase.auth.GithubAuthProvider())
            }>
            <Icon className='fab fa-github' />
            <span>Sign in with Github</span>
          </Button>
        </Control>
      </Field>
    );
  }

  return (
    <Navbar className='is-spaced' style={{ backgroundColor: '#ffffff00' }}>
      <Container>
        <NavbarBrand>
          <NavbarItem href='/'>
            <img src='/assets/images/autolighthouse_100h.png' />
          </NavbarItem>
          <NavbarBurger
            isActive={isNavOpen}
            onClick={() => setIsNavOpen(!isNavOpen)}
          />
        </NavbarBrand>
        <NavbarMenu
          isActive={isNavOpen}
          onClick={() => setIsNavOpen(!isNavOpen)}>
          {/*<NavbarStart>
            <NavbarItem>
              {!initialising && user ? <TeamDropdown user={user} /> : null}
            </NavbarItem>
          </NavbarStart>*/}
          <NavbarEnd>
            {!initialising && user ? (
              <NavbarItem href='/settings'>Account Settings</NavbarItem>
            ) : null}
            <NavbarItem>{authButton()}</NavbarItem>
          </NavbarEnd>
        </NavbarMenu>
      </Container>
    </Navbar>
  );
};

export const TeamTabs = (props: {
  selected: 'sites' | 'team_settings';
}): React.ReactElement => {
  return (
    <>
      <Button
        isColor='primary'
        style={{ fontWeight: props.selected == 'sites' ? 'bold' : 'normal' }}>
        Sites
      </Button>
      &nbsp; &nbsp;
      <Button
        isColor='primary'
        style={{
          fontWeight: props.selected == 'team_settings' ? 'bold' : 'normal',
        }}>
        Team Settings
      </Button>
    </>
  );
};

export const SiteTabs = (props: {
  selected: 'overview' | 'deploys' | 'settings';
}): React.ReactElement => {
  return (
    <>
      <Button
        isColor='primary'
        style={{
          fontWeight: props.selected == 'overview' ? 'bold' : 'normal',
        }}>
        Overview
      </Button>
      &nbsp; &nbsp;
      <Button
        isColor='primary'
        style={{
          fontWeight: props.selected == 'deploys' ? 'bold' : 'normal',
        }}>
        Deploys
      </Button>
      &nbsp; &nbsp;
      <Button
        isColor='primary'
        style={{
          fontWeight: props.selected == 'settings' ? 'bold' : 'normal',
        }}>
        Settings
      </Button>
    </>
  );
};
