const rtf = new Intl.RelativeTimeFormat('en');

export function relativeTimeFormatSince(prevDate: Date): string {
  const currDate = new Date();
  const diff = currDate.getTime() - prevDate.getTime();

  const minute = 1000 * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const year = day * 365;
  const month = year / 12;

  if (diff < minute) {
    return rtf.format(Math.round(-diff / 1000), 'second');
  } else if (diff < hour) {
    return rtf.format(Math.round(-diff / minute), 'minute');
  } else if (diff < day) {
    return rtf.format(Math.round(-diff / hour), 'hour');
  } else if (diff < month) {
    return rtf.format(Math.round(-diff / day), 'day');
  } else if (diff < year) {
    return 'about ' + rtf.format(Math.round(-diff / month), 'month');
  } else {
    return rtf.format(Math.round(-diff / year), 'year');
  }
}
