import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Control,
  Input,
  Title,
  Subtitle,
  Box,
  Columns,
  Column,
  Button,
  Icon,
} from 'bloomer';
import './sites.scss';

import firebase from '@firebase/app';
import { useCollection } from 'react-firebase-hooks/firestore';
import '@firebase/storage';
import { useDownloadURL } from 'react-firebase-hooks/storage';

import { ErrorBoxWrapped } from './error';
import { LoadingBoxWrapped } from './loading';
import { relativeTimeFormatSince } from '../util/time';

export const Sites = (props: { team: string }): React.ReactElement => {
  const { value, loading, error } = useCollection(
    firebase
      .firestore()
      .collection('sites')
      .where('team', '==', props.team)
  );

  if (error) {
    return <ErrorBoxWrapped error={error} />;
  }

  if (loading) {
    return <LoadingBoxWrapped />;
  }

  return (
    <>
      <br />
      <br />
      <Box isPaddingless>
        <div style={{ padding: 20 }}>
          <Columns>
            <Column isSize='1/2'>
              <Control>
                <Input placeholder='Search' className='is-rounded' />
              </Control>
            </Column>
            <Column isPaddingless />
            <Column isSize='1/4'>
              <Button isColor='dark' isFullWidth>
                New site
              </Button>
            </Column>
          </Columns>
        </div>
        <div>
          {value.docs.map(doc => {
            const val = doc.data();
            return (
              <Site
                key={doc.id}
                id={doc.id}
                name={val.name}
                lastUpdated={val.last_updated.toDate()}
                team={props.team}
              />
            );
          })}
        </div>
      </Box>
    </>
  );
};

const Site = (props: {
  id: string;
  name: string;
  lastUpdated: Date;
  team: string;
}): React.ReactElement => {
  const { value, loading, error } = useDownloadURL(
    firebase
      .storage()
      .refFromURL(`gs://auto-lighthouse-screenshots/${props.id}.png`)
  );

  return (
    <Link to={`/teams/${props.team}/sites/${props.id}`} className='site'>
      <div className='screenshot'>
        <img
          src={
            loading
              ? ''
              : !error && value
              ? value
              : '/assets/images/screenshot_empty.png'
          }
        />
      </div>
      <div style={{ width: 'calc(100% - 150px)' }}>
        <Title isSize={5} className='truncate'>
          {props.name}
        </Title>
        <Subtitle isSize={6}>
          Last updated {relativeTimeFormatSince(props.lastUpdated)}
        </Subtitle>
      </div>
      <div className='icon-go'>
        <Icon isSize='medium' className='fas fa-chevron-right' />
      </div>
    </Link>
  );
};
