import * as React from 'react';
import { Box, Title, Column, Subtitle } from 'bloomer';
import { Columns } from 'bloomer/lib/grid/Columns';
import { relativeTimeFormatSince } from '../../util/time';
import { Gauge } from './gauge';

export const SiteOverview = (props: { team: string; site: string }) => {
  return (
    <>
      <br />
      <br />
      <Columns>
        <Column isSize={5}>
          <Box style={{ height: '100%' }}>
            <Title isSize={5}>Antipy Website</Title>
            <Subtitle isSize={6}>
              <a href='https://antipy.com'>https://antipy.com</a>
            </Subtitle>
            <p>
              Last updated {relativeTimeFormatSince(new Date('10 may 2019'))}
            </p>
          </Box>
        </Column>
        <Column isSize={7}>
          <Box style={{ height: '100%' }}>
            <Title isSize={4}>Report</Title>
            <Columns isMobile>
              <Column className='is-one-fifth'>
                <Gauge points={91} name='Performance' />
              </Column>
              <Column className='is-one-fifth'>
                <Gauge points={76} name='Accessibility' />
              </Column>
              <Column className='is-one-fifth'>
                <Gauge points={49} name='Best Practices' />
              </Column>
              <Column className='is-one-fifth'>
                <Gauge points={83} name='SEO' />
              </Column>
              <Column className='is-one-fifth'>
                <Gauge points={100} name='PWA' />
              </Column>
            </Columns>
          </Box>
        </Column>
      </Columns>
      <Columns>
        <Column isSize='1/2'>
          <Box>
            <Title isSize={4}>Deploys</Title>
          </Box>
        </Column>
      </Columns>
    </>
  );
};
