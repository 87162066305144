import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Nav } from './components/nav';
import { Welcome } from './routes/welcome';

import firebase from '@firebase/app';
import '@firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Wrapper } from './components/wrapper';
import { AppAuthenticated } from './signed_in';
import { Loading } from './components/loading';

export const App = (): React.ReactElement => {
  const { initialising, user } = useAuthState(firebase.auth());

  if (initialising) {
    return <Loading />;
  }

  if (user) {
    return <AppAuthenticated />;
  }

  return (
    <div>
      <Nav />
      <Switch>
        <Route path='/' exact render={r => <Welcome />} />
        <Route render={r => <Redirect to='/' />} />
      </Switch>
    </div>
  );
};
