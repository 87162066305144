import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { TeamSites } from './routes/team_sites';
import { Loading, DefaultTeamWaiting } from './components/loading';
import { isUserInTeam } from './util/team';
import firebase from '@firebase/app';
import { useCollection } from 'react-firebase-hooks/firestore';
import { ErrorDisplay } from './components/error';
import { TeamSiteRouter } from './routes/team_site';

export const AppAuthenticated = (): React.ReactElement => {
  return (
    <div>
      <Switch>
        <Route
          path='/teams/:team'
          render={r => <TeamRouter team={r.match.params.team} />}
        />
        <Route path='/' exact render={r => <TeamSwitcher />} />
        <Route render={r => <Redirect to='/' />} />
      </Switch>
    </div>
  );
};

const TeamRouter = (props: { team: string }): React.ReactElement => {
  const [role, setRole] = React.useState('');
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    isUserInTeam(firebase.auth().currentUser, props.team)
      .then(role => {
        setRole(role);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setRole('');
        setLoading(true);
      });
  }, [props.team]);

  if (loading) {
    return <Loading />;
  }

  if (role == '') {
    return <Redirect to='/' />;
  }

  // routePrefix
  const rp = `/teams/${props.team}`;

  return (
    <>
      <Switch>
        <Route
          path={rp + '/sites/:site'}
          render={r => (
            <TeamSiteRouter team={props.team} site={r.match.params.site} />
          )}
        />
        <Route
          path={rp + '/sites'}
          exact
          render={r => <TeamSites team={props.team} />}
        />
        <Route path={rp} exact render={r => <Redirect to={rp + '/sites'} />} />
        <Route render={r => <Redirect to={rp} />} />
      </Switch>
    </>
  );
};

const TeamSwitcher = () => {
  const { value, loading, error } = useCollection(
    firebase
      .firestore()
      .collection('teams')
      .where('is_default_team_for', '==', firebase.auth().currentUser.uid)
  );

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (value.docs.length < 1) {
    return <DefaultTeamWaiting />;
  }

  const team = value.docs[0].id;

  return <Redirect to={`/teams/${team}`} />;
};
