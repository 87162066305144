import { User } from '@firebase/auth-types';

import firebase from '@firebase/app';
import '@firebase/firestore';

export const isUserInTeam = async (
  user: User,
  team: string
): Promise<'admin' | 'developer' | 'viewer' | ''> => {
  const doc = await firebase
    .firestore()
    .collection('teams')
    .doc(team)
    .get();

  const users = doc.get('users');

  return users[user.uid];
};
