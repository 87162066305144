import * as React from 'react';
import { Sites } from '../components/sites';
import { Wrapper } from '../components/wrapper';
import { Nav, TeamTabs } from '../components/nav';

export const TeamSites = (props: { team: string }): React.ReactElement => {
  return (
    <>
      <Nav />
      <Wrapper>
        <TeamTabs selected='sites' />
        <Sites team={props.team} />
      </Wrapper>
    </>
  );
};
