import * as React from 'react';
import { Nav } from './nav';
import { Wrapper } from './wrapper';

export const Loading = () => {
  return (
    <>
      <Nav />
      <Wrapper>
        <LoadingBox />
      </Wrapper>
    </>
  );
};

export const LoadingBoxWrapped = () => {
  return (
    <>
      <Wrapper>
        <LoadingBox />
      </Wrapper>
    </>
  );
};

export const LoadingBox = () => {
  return (
    <>
      <br />
      <p>Loading...</p>
    </>
  );
};

export const DefaultTeamWaiting = () => {
  return (
    <>
      <Nav />

      <Wrapper>
        <br />
        <p>We are now creating your account. Please hang tight...</p>
      </Wrapper>
    </>
  );
};
