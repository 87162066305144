import * as React from 'react';
import { Nav } from './nav';
import { Wrapper } from './wrapper';
import { Box } from 'bloomer';

export const ErrorDisplay = (props: { error: Error }) => {
  return (
    <>
      <Nav />

      <Wrapper>
        <ErrorBox error={props.error} />
      </Wrapper>
    </>
  );
};

export const ErrorBoxWrapped = (props: { error: Error }) => {
  return (
    <>
      <Wrapper>
        <ErrorBox error={props.error} />
      </Wrapper>
    </>
  );
};

export const ErrorBox = (props: { error: Error }) => {
  console.error(props.error.message);

  return (
    <>
      <br />
      <br />
      <Box>
        We encountered an unexpected error! You can open the console to check
        what went wrong.
      </Box>
    </>
  );
};
