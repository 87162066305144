import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Wrapper } from '../components/wrapper';
import { Nav, SiteTabs } from '../components/nav';
import { SiteOverview } from '../components/site/overview';

export const TeamSiteRouter = (props: {
  team: string;
  site: string;
}): React.ReactElement => {
  const rp = `/teams/${props.team}/sites/${props.site}`;

  return (
    <Switch>
      <Route
        path={rp + '/overview'}
        exact
        render={r => <Overview {...props} />}
      />
      <Route
        path={rp + '/'}
        exact
        render={r => <Redirect to={rp + '/overview'} />}
      />
      <Route render={r => <Redirect to={rp} />} />
    </Switch>
  );
};

const Overview = (props: { team: string; site: string }) => {
  return (
    <>
      <Nav />
      <Wrapper>
        <SiteTabs selected='overview' />
        <SiteOverview {...props} />
      </Wrapper>
    </>
  );
};
