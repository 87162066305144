import * as React from 'react';
import { render } from 'react-dom';

import { BrowserRouter as Router } from 'react-router-dom';

import { App } from './app';

import firebase from '@firebase/app';
import '@firebase/performance';
import '@firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyB5E-3f0OSTCIEyx6KU2Em0_XtAL3MBFcw',
  authDomain: 'auto-lighthouse.firebaseapp.com',
  databaseURL: 'https://auto-lighthouse.firebaseio.com',
  projectId: 'auto-lighthouse',
  storageBucket: 'auto-lighthouse.appspot.com',
  messagingSenderId: '504841586013',
  appId: '1:504841586013:web:b5127f1f36a70529',
};

firebase.initializeApp(firebaseConfig);
const perf = firebase.performance();

render(
  <Router>
    <App />
  </Router>,
  document.getElementById('app')
);
