import * as React from 'react';
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export const Gauge = (props: { points: number; name: string }) => {
  let stroke, color, color_background;

  if (props.points >= 90) {
    stroke = `rgba(12, 206, 107, 1)`;
    color = 'rgba(12, 206, 107, 1)';
    color_background = 'rgba(12, 206, 107, 0.1)';
  } else if (props.points >= 50) {
    stroke = `rgba(255, 164, 0, 1)`;
    color = 'rgba(255, 164, 0, 1)';
    color_background = 'rgba(255, 164, 0, 0.1)';
  } else {
    stroke = `rgba(255, 78, 66, 1)`;
    color = 'rgba(255, 78, 66, 1)';
    color_background = 'rgba(255, 78, 66, 0.1)';
  }

  return (
    <div style={{ width: '100%' }}>
      <CircularProgressbar
        percentage={props.points}
        text={`${props.points}`}
        background
        styles={{
          root: { width: '100%' },
          path: {
            stroke: stroke,
            transition: 'all 0.5s ease 0s',
          },
          trail: {
            stroke: '#ffffff00',
          },
          text: {
            fill: color,
          },
          background: {
            fill: color_background,
          },
        }}
      />
      <p style={{ textAlign: 'center' }}>{props.name}</p>
    </div>
  );
};
