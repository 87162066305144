import * as React from 'react';
import { Container } from 'bloomer';

export const Wrapper = (props: {
  children: React.ReactElement | React.ReactElement[] | string;
}): React.ReactElement => {
  return (
    <>
      <Container
        style={{
          margin: '0 auto',
          maxWidth: 'calc(100% - 40px)',
          padding: '12px 0',
        }}>
        {props.children}
      </Container>
    </>
  );
};
